import CRUDService from './CRUDService';
import http from '@/utils/http';

interface LinkRequest {
  id?: string;
  type?: 'COMPANY' | 'PERSON',
  tenant_id: string,
  vat_number?: string,
  firstname?: string,
  lastname?: string,
  force?: boolean
}

class ContactService extends CRUDService {
  constructor(path) {
    super(path);
  }

  link(data: LinkRequest) {
    return http({
      url: `api/${this.path}/link`,
      method: 'post',
      data
    });
  }

  exportContactsData(query) {
    return http({
      url: `api/${this.path}/export`,
      params: query,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      timeout: 0
    });
  }

  getHistory(params) {
    return http({
      url: `api/${this.path}/history`,
      method: 'get',
      params
    });
  }
}

export default new ContactService('contacts');
